@import '../../../sass/globals';

.content-section{
    &.formation{
        p{
            margin:0;
            display: inline-block;
            font-size: 18px;
            line-height: 18px;
            font-style: italic;
            padding: 0 10px 0 0;
            margin: 0 10px 0 0;
        }
        @media screen{
            .title{
                h2{
                    .placeholder{
                        width: 210px;
                    }
                }
            }
            
            h3{
                .placeholder{
                    width: 165px;
            
                    p{
                        &:first-child{
                            width: 160px;
                        }
                    }
                }
            }
            
            p{
                &:nth-of-type(1){
                    .placeholder{
                        width: 240px;
                    }
                }
                &:nth-of-type(2){
                    .placeholder{
                        width: 50px;
                    }
                }
            }
            @media ($phone){
                p{
                    width: 100%;

                    .placeholder{
                        margin: 0;
                    }
                    &:nth-of-type(1){
                        .placeholder{
                            width: 200px;
                        }
                    }
                }
            }
        }
    }
}

@media print{
    .content-section{
        &.formation{
            .section-content{
                .item{
                    p{
                        &.bullet{
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}