@import '../../../sass/globals';

@media screen{
    .title{
        h2{
            .placeholder{
                width: 240px;
                height: 28px;
            }
        }
    }
}

@media print{
    .title{
        h2{
            display: block;
            float: left;
            line-height: 50px;
            margin: 0;
        }
    }
}