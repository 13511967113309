@import '../../../sass/globals';

.content-container{
    .content-section{
        .section-content{
            .item{
                ul.job-tt{
                    li{
                        margin: 10px 10px 0 0;
                        float: left;
                        padding: 6px 12px;
                        background: $c-tagsBackground;
                        border-radius: 3px;

                        @media print{
                            background: transparent;
                            border: 1px solid $c-tagsBackground;
                        }
                    }
                }
            }
        }
        @media screen{
            &.loading{
                .section-content{
                    .item{
                        .job-tt{
                            li{
                                padding: 0;
                                overflow: hidden;
                                
                                .placeholder{
                                    width: 80px;
                                    height: 32px;
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen{
    .content-container{
        max-width: 900px;
        width: 100%;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        background: $c-contentContainerBG;
        display: inline-block;
        margin: 0 auto;
        padding: 30px;
        color: $c-textDark;
        position: relative;

        &:before{
            content: '';
            position: absolute;
            top: 30px;
            left: 54px;
            background: $c-textDark;
            width: 3px;
            height: calc( 100% - 55px );
            z-index: 0;
            transition: background .3s ease-out;
        }
        &.content-loading{
            &:before{
                background: $c-textLight;
            }
        }

        .content-section{
            margin: 30px 0 0 0;

            &:first-child{
                margin-top: 0;
            }
            .title{
                display: flex;
                width: 100%;
                justify-content: flex-start;
                align-items: center;

                h2{
                    margin: 0;
                    font-size: 22px;
                }
            }
            .section-content{
                text-align: left;
                padding-left: 70px;

                h3{
                    margin: 0 0 10px 0;
                    font-size: 20px;
                    line-height: 20px;
                    font-weight: 400;
                }

            }
            p{
                margin: 0;
                font-size: 18px;
                line-height: 18px;

                .placeholder{
                    height: 18px;
                    display: block;
                }
            }
            h3{
                .placeholder{
                    width: 165px;
                    height: 20px;
                    margin: 0 0 -2px 0;
                    display: block;
                }
            }
        }
        @media ($phone){
            padding: 15px 10px;

            &:before{
                left: 34px;
                height: calc( 100% - 30px);
            }
            .content-section{    
                .title{    
                    h2{
                        font-size: 20px;
                        line-height: 20px;
                        white-space: nowrap;
                    }
                }
                .section-content{
                    padding-left: 65px;

                    h3{
                        font-size: 18px;
                        line-height: 18px;
                    }
                }
                p{
                    font-size: 16px;
                    line-height: 18px;
                }
            }
        }
    }
}

@media print{
    .content-container{
        margin-top: 20px;
        float: left;
        width: 100%;
        position: relative;
        padding-left: 40px;

        &:before{
            content: '';
            position: absolute;
            top: -20px;
            left: 65px;
            background: transparent;
            width: 0;
            height: calc( 100% + 20px );
            z-index: 0;
            border-right: 3px solid $c-printText;
        }

        .content-section{
            float: left;
            text-align: left;
            width: 100%;
            margin: 15px 0 0 0;

            .title{
                float: left;
                width: 100%;
                position: relative;
                z-index: 1;
            }
            .section-content{
                float: left;
                width: 100%;
                padding-left: 65px;
                
                h3{
                    margin: 0;
                }
                p{
                    margin: 0;
                }
            }
        }
    }
}