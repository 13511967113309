@import '../../../sass/globals';

@media screen{
    .profile-name{
        color: $c-textLight;

        h1{
            font-size: 22px;
            text-transform: uppercase;
            letter-spacing: 4px;
            margin: 20px 0 0 0;
        }
        h3{
            font-size: 16px;
            font-weight: 300;
            margin: 0;
        }

        &.placeholder{
            h1{
                height: 25px;
                margin: 20px auto 5px auto;
            }
            h3{
                height: 18px;
                margin: 0 auto;
            }
            h1,
            h3{
                max-width: 180px;
            }
        }
    }
}

@media print{
    .profile-name{
        float: left;
        padding: 8px 0 0 30px;
        text-align: left;

        h1{
            margin: 0;
            font-size: 32px;
        }
        h3{
            margin: 0 0 5px 0;
        }
    }
}