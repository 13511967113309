@import './sass/globals';

body {
    margin: 0;
    font-family: $font-general;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    *{
        box-sizing: border-box;
    }
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
ul{
    list-style: none;
    margin: 0;
    padding: 0;
}
.bullet{
    position: relative;

    &:before{
        content: '';
        width: 4px;
        height: 4px;
        background: $c-textDark;
        position: absolute;
        right: -2px;
        top: 50%;
        margin: -2px 0 0 0;
        border-radius: 50%;

        @media ($phone){
            content: none;
        }
        @media print{
            content: '\2022';
            line-height: 1px;
            background: transparent;
        }
    }
}
.content-loading{
    .bullet{
        &:before{
            background: $c-textLight;
        }
    }
}

.button-primary{
    background: $c-accentPrimary;
    color: adjust-color($c-accentPrimary, $lightness: -20%);
    border-color: adjust-color($c-accentPrimary, $lightness: -20%);
}


@keyframes rtz-loader {
    0% { 
        transform: rotate(0deg);
    }
    50%{
        transform: rotate(180deg);
    }
    100% { 
        transform: rotate(360deg); 
    }
}

.rtz-loader{
    width: 20px;
    height: 20px;

    .spinner{
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-width: 2px;
        border-style: solid;
        border-color: $c-loader;
        border-top-color: transparent !important;
        border-radius: 50%;
        animation-name: rtz-loader;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        transition: all .2s ease-out;
    }
}

.placeholder {
    margin: 0 auto;
    width: 100%;
    min-height: 10px;
}

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

.animated-background{
    background: #D0D0D0;
    background: linear-gradient(135deg, #E5E5E5 10%, #D0D0D0 18%, #E5E5E5 33%);
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-size: 800px 100%;
    height: 100%;
    width: 100%;
    position: relative;
    display: block;
}

.animated-background-dark{
    background: $c-darkBorders;
    background: linear-gradient(135deg, $c-darkBorders 10%, #555555 18%, $c-darkBorders 33%);
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-size: 800px 100%;
    height: 100%;
    width: 100%;
    position: relative;
}

@media print{
    body{
        padding: 1cm .7cm 0 .5cm;
        color: $c-printText;
        float: left;
    }
    .hide-print{
        display: none !important;
    }
}

@page { 
    size: auto;  
    margin-top: 0cm;
    margin-bottom: 0cm;
    margin-right: .3cm;
    margin-left: .5cm;
}
@page :second{
    margin: 10cm;
}