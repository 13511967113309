// General Colors
$c-accentPrimary: #0F90C0;
$c-accentSecondary: #4A4A4A;
$c-textLight: #E0E0E0;
$c-textDark: #606060;
$c-darkBorders: #4A4A4A;
$c-loaderBase: #606060;
$c-loader: #606060;
$c-darkBackground: #333333;
$c-contentBG: #E5E5E5;
$c-contentContainerBG: #F0F0F0;
$c-tagsBackground: #D0D0D0;
$c-error: #f57373;
$c-success: #79f573;

// Pure Colors
$c-white: #FFFFFF;

// Fonts
$font-general: 'Source Sans Pro', sans-serif;

// Medias
$mobile: 'max-width: 1024px';
$phone: 'max-width: 767px';
$tablet: 'min-width: 768px) and (max-width: 1024px';
$desktop: 'min-width: 1025px';

// Print
$c-printText: #303030;
