@import '../../../sass/globals';

@media screen{
    .language-nav{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        list-style: none;
        padding: 0;
        margin: 0;
        background: $c-darkBorders;
        color: $c-textLight;
    
        li{
            float: left;
            width: 50%;
            padding: 20px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 300;
            cursor: pointer;
            transition: color .3s ease-out;
            transition-property: font-weight, color;
    
            &:first-child{
                &:before{
                    content: '';
                    width: 6px;
                    height: 6px;
                    background: $c-textLight;
                    position: absolute;
                    right: -3px;
                    border-radius: 50%;
                }
            }
            &.active{
                font-weight: 600;
            }
        }

        @media ($desktop){
            li{
                &:hover{
                    font-weight: 600;
                    color: $c-white;
                }
            }
        }
        @media ($mobile){
            position: static;
            float: left;
        }
    }
}