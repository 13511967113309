@import '../../../sass/globals';

@media screen{
    .content{
        width: 100%;
        height: 100vh;
        padding: 60px 25px 60px 375px;
        background: $c-contentBG;
        overflow-y: auto;

        @media ($mobile){
            padding: 20px;
            overflow: hidden;
            height: auto;
        }
        @media($phone){
            padding: 0;
        }
    }
}

@media print{
    .content{
        float: left;
        height: auto;
    }
}
