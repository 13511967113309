@import '../../../sass/globals';

.content-section{
    &.experience{
        .section-content{
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-flow: row wrap;

            .item{
                margin: 15px 0 0 0;
                width: 100%;

                &:first-child{
                    margin-top: 0;
                }
                .experience-title{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-size: 20px;
                    line-height: 20px;
                    margin: 0 0 10px 0;

                    h3{
                        margin: 0 10px 0 0;
                        padding: 0 10px 0 0;
                        font-weight: 600;
                    }

                    .experience-dates{
                        font-size: 18px;
                        line-height: 18px;
                    }

                    p{
                        margin-right: 10px;

                        &.company{
                            position: relative;
                            padding: 0 10px 0 0;

                            &:after{
                                content: '-';
                                text-align: center;
                                width: 18px;
                                height: 18px;
                                position: absolute;
                                right: -9px;
                                transition: color .3s ease-out;
                            }
                        }
                        &.date{
                            text-transform: capitalize;
                        }
                        &.date-from{
                            margin: 0 5px 0 0;
                        }
                        &.date-to{
                            margin: 0 0 0 5px;
                        }
                    }
                    .date-separator{
                        transition: color .3s ease-out;
                    }
                }
                .description{
                    line-height: 22px;
                    font-style: italic;

                }
            }
        }  
             
        @media screen{
            &.loading{
                .section-content{
                    .item{
                        .experience-title{
                            >*{
                                height: 20px;
                            }
                        }
                        p{
                            &.company{
                                .placeholder{
                                    width: 200px;
                                    margin: 0
                                }
                            }
                            &.date{
                                .placeholder{
                                    width: 70px;
                                }
                            }
                        }
                    }
                }
                .date-separator{
                    color: $c-textLight;
                }
                p{
                    &.company{
                        &:after{
                            color: $c-textLight;
                            top: 0;
                        }
                    }
                }
                .description{
                    width: 100%;
                    margin: 0;

                    &.line-end{
                        width: 45%;
                    }

                    .placeholder{
                        width: 95%;
                        max-width: 430px;
                        margin: 0;
                    }
                }
            }
            .section-content{
                .item{
                    text-align: left;

                    .experience-title{
                        .placeholder{
                            height: 20px;
                        }
                    }
    
                    h3{
                        .placeholder{
                            width: 100px;
                        }
                    }
                    p{
                        display: inline-block;
                    }
                }
                @media ($phone){
                    .item{
                        .experience-title{
                            flex-flow: row wrap;

                            h3, .company, .experience-dates{
                                width: 100%;
                            }
                            p{
                                &.company{
                                    margin: 5px 0;
    
                                    &:after{
                                        content: none;
                                    }
                                }
                            }
                        }
                        p.description{
                            padding-right: 10px;
                        }
                    }
                }
            }
        }
    }

}

@media print{
    .content-section{
        &.experience{
            .section-content{
                display: block;
                width: 100%;

                .item{
                    float: left;
                    width: 100%;
                    break-inside: avoid;
                    page-break-inside: avoid;
                    margin-top: .5cm;

                    .experience-title{
                        display: block;
                        float: left;
                        width: 100%;

                        h3{
                            font-size: 20px;
                            display: inline-block;
                        }
                        .company{
                            display: inline-block;
                        }
                        .experience-dates{
                            display: inline-block;

                            p, span{
                                display: inline-block;
                            }
                        }
                    }
                    p.description{
                        float: left;
                        width: 100%;
                    }
                    .job-tt{
                        float: left;
                        width: 100%;
                        break-inside: avoid;
                        page-break-inside: avoid;
                    }
                }
            }
        }
    }
}