@import '../../../sass/globals';

.content-section{
    &.tools-techs{
        .section-content{
            .item{
                width: 100%;
                margin: 10px 0 0 0;

                h3{
                    margin-bottom: 0;
                }

                &:first-child{
                    margin: 0;
                }

                ul.job-tt{
                    display: inline-block;
                    width: 100%;
                }
            }
        }
        @media screen{
        
        }
    }
}
