@import '../../../sass/globals';

@media screen{
    .general-information{
        padding: 0;
        margin: 0;
        list-style: none;
        font-family:  $font-general;
        font-weight: 300;
        font-size: 16px;
        color: $c-textLight;
        border-top: 1px solid $c-darkBorders;
        margin: 20px 0 0 0;
        padding: 0 20px;
    
        li{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px 0 0 0;
    
            i{
                margin-right: 10px;
                font-size: 18px;
            }
        }
        .side-box{
            margin: 20px 0 0 0;
            padding: 15px 20px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: auto;
            border-radius: 3px;
        }
        .loading{
            width: 200px;
            border: 1px solid $c-darkBorders;
            color: $c-loaderBase;
            font-weight: 400;
    
            .rtz-loader{
                margin-right: 10px;
    
                .spinner{
                    border-color: $c-textLight;
                }
            }
        }
        .placeholder{
            height: 20px;
            margin-top: 20px;

            span{
                max-width: 200px;
                border-radius: 3px;
            }
        }
        .unlock-private{
            border-width: 1px;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 16px;
            cursor: pointer;
    
            i{
                margin-right: 15px;
            }
        }
    }
}

@media print{
    .general-information{
        float: right;
        text-align: right;
        font-family:  $font-general;
        font-weight: 300;
        font-size: 16px;
        margin-top: 10px;

        li{
            display: block;
            width: 100%;
            padding: 5px 0;

            i{
                margin-right: 10px;
            }
        }
    }
}