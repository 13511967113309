@import '../../../sass/globals';

.content-section{
    &.languages{
        .section-content{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .item{
                margin-right: 25px;
            }
        }  

        @media screen{
            .section-content{
                max-width: 550px;

                .item{
                    text-align: left;
    
                    h3{
                        .placeholder{
                            width: 100px;
                        }
                    }
                    p{
                        display: inline-block;
    
                        .placeholder{
                            width: 65px;
                            display: inline-block;
                        }
                    }
                }
            }
            @media ($phone){
                .section-content{
                    .item{
                        h3{
                            .placeholder{
                                width: 70px;
                            }
                        }
                    }
                }
            }
        }
    }

}
