@import '../../../sass/globals';

@media screen{
    .sidebar-actions{
        display: inline-block;
        width: 100%;
        color: $c-textLight;
        margin: 20px 0 0 0;

        li{
            float: left;
            width: 33.33%;
            border: 1px solid $c-darkBorders;
            border-right: none;
            line-height: 56px;
            cursor: pointer;
            transition: background .3s ease-out;

            &:first-child{
                border-left: none;
            }
            i{
                font-size: 22px;
            }
            a{
                color: inherit;
                display: block;
            }
        }

        @media ($desktop){
            li{
                &:hover{
                    background: $c-textLight;
                    color: $c-darkBackground;
                }
            }
        }
        @media ($mobile){
            float: left;
        }
    }
}

@media print{
    .sidebar-actions{
        float: left;
        width: 50%;
        padding-left: 30px;

        li{
            text-align: left;
            padding: 2px 0 0 0;

            a{
                color: $c-printText;
                text-decoration: none;
                font-weight: 300;

                &:after{
                    margin-left: 10px;
                    content: attr(data-print-link);
                }
            }
        }
    }
}