@import '../../../sass/globals';

@media screen{
    .sidebar{
        background: $c-darkBackground;
        position: fixed;
        top: 0;
        left: 0;
        width: 350px;
        height: 100vh;
        text-align: center;
        border-right: 5px solid $c-darkBorders;
        
        @media ($mobile){
            position: static;
            height: auto;
            width: 100%;
            float: left;
            border: 0;
        }
    }

}

@media print{
    .sidebar{
        width: 100%;
        height: auto;
        float: left;
    }
}