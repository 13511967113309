@import '../../../sass/globals';

@media screen{
    .message-modal{
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $c-textLight;
        display: none;
        opacity: 0;
        transition: all .4s ease-in;

        &.active{
            opacity: 1;

            .modal-overlay{
                opacity: 1;
            }
        }
        
        .modal-content{
            background: $c-darkBackground;
            position: relative;
            z-index: 1;
            border-radius: 4px;
            padding: 40px;
            width: 100%;
            max-width: 600px;
            display: flex;
            align-items: center;

            .close-modal{
                position: absolute;
                top: 0;
                right: 0;
                width: 50px;
                line-height: 50px;
                text-align: center;

                @media ($desktop){
                    &:hover{
                        cursor: pointer;
                    }
                }
            }
            .modal-icon{
                font-size: 30px;
            }
            .modal-content-container{
                text-align: left;
                padding-left: 30px;

                h2{
                    margin: 0;
                    font-size: 20px;
                }
                p{
                    margin: 10px 0 0 0;
                    font-weight: 300;
                }
            }
        }

        .modal-overlay{
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0, .6);
            z-index: 0;
            opacity: 0;
            transition: all .3s ease-out;
        }

        @media ($mobile){
            align-items: flex-start;
            top: 0;
            padding: 30px 0;
            overflow: auto;

            &.active{
                .modal-content{
                    top: 0;
                    border-color: $c-darkBorders;
                }
            }

            .modal-content{
                margin: 0 15px;
                padding: 40px 15px;
                position: relative;
                top: -130px;
                transition: all .3s ease-out;
                border: 1px solid transparent;
            }
        }
    }
}