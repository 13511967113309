@import '../../../sass/globals';

@media screen{
    .token-modal{
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $c-textLight;
        display: none;
        opacity: 0;
        transition: all .4s ease-in;

        &.active{
            opacity: 1;

            .modal-overlay{
                opacity: 1;
            }
        }
        
        .modal-content{
            background: $c-darkBackground;
            position: relative;
            z-index: 1;
            border-radius: 4px;
            padding: 40px;
            width: 100%;
            max-width: 600px;

            .intro{
                font-size: 18px;
                font-weight: 300;
                margin-top: 5px;
            }

            .close-modal{
                position: absolute;
                top: 0;
                right: 0;
                width: 50px;
                line-height: 50px;
                text-align: center;

                @media ($desktop){
                    &:hover{
                        cursor: pointer;
                    }
                }
            }
            form{
                display: flex;
                justify-content: stretch;
                align-items: center;

                input{
                    width: calc( 100% - 100px );
                    height: 40px;
                    border-radius: 3px 0 0 3px;
                    border: none;
                    padding: 0 15px;
                    box-sizing: border-box;

                    &:focus{
                        outline: none;
                    }
                }
                .actions{
                    width: 120px;

                    button{
                        width: 100%;
                        height: 40px;
                        border: none;
                        border-radius: 0 3px 3px 0;
                        font-weight: 600;
                        text-transform: uppercase;

                        i{
                            margin-right: 5px;
                        }

                        @media ($desktop){
                            &:hover{
                                cursor: pointer;
                            }
                        }

                        &:focus{
                            outline: none;
                        }
                    }
                }
            }
            .msg-description{
                background: $c-accentPrimary;
                color: adjust-color($c-accentPrimary, $lightness: -20%);
                border-color: adjust-color($c-accentPrimary, $lightness: -20%);
                border-width: 1px;
                border-style: solid;
                border-radius: 3px;
                width: auto;
                display: inline-block;
                padding: 0 20px;
                line-height: 50px;
                margin-bottom: 0;

                &.hidden{
                    display: none;
                }
                &.invalid{
                    background: $c-error;
                    color: adjust-color($c-error, $lightness: -40%);
                    border-color: adjust-color($c-error, $lightness: -40%);
                }
                &.valid{
                    background: $c-success;
                    color: adjust-color($c-success, $lightness: -40%);
                    border-color: adjust-color($c-success, $lightness: -40%);
                }
                .rtz-loader{
                    display: block;
                    margin-right: 10px;
                    margin: 14px 10px 0 0;
                    float: left;

                    .spinner{
                        border-color: adjust-color($c-accentPrimary, $lightness: -20%);
                    }
                }
                i{
                    margin-right: 10px;
                }
            }
        }

        .modal-overlay{
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0, .6);
            z-index: 0;
            opacity: 0;
            transition: all .3s ease-out;
        }

        @media ($mobile){
            align-items: flex-start;
            top: 0;
            padding: 30px 0;
            overflow: auto;

            &.active{
                .modal-content{
                    top: 0;
                    border-color: $c-darkBorders;
                }
            }

            .modal-content{
                margin: 0 15px;
                position: relative;
                top: -130px;
                transition: all .3s ease-out;
                border: 1px solid transparent;
    
                form{
                    flex-flow: row wrap;
    
                    input{
                        width: 100%;
                        border-radius: 3px;
                        height: 50px;
                    }
                    .actions{
                        width: 100%;

                        button{
                            margin: 10px 0 0 0;
                            border-radius: 3px;
                            height: 50px;
                        }
                    }
                }
                .msg-description{
                    width: 100%;
                    text-align: left;

                    i{
                        float: left;
                        margin-right: 15px;
                        margin-top: 18px;
                    }
                    .rtz-loader{
                        margin: 14px 15px 0 0;
                    }
                }
            }
        }
    }
}