@import '../../../sass/globals';

.content-loading{
    .section-icon{
        border-color: #E0E0E0;
    }
}

.section-icon{
    width: 50px;
    height: 50px;
    border: 3px solid $c-textDark;
    border-radius: 50px;
    display: inline-block;
    line-height: 44px;
    text-align: center;
    font-size: 22px;
    margin-right: 20px;
    z-index: 1;
    background: $c-contentContainerBG;
    flex: 0 0 50px;

    .placeholder{
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
    }

    @media ($phone){
        margin-right: 15px;
    }
}

@media print{
    .section-icon{
        float: left;
        display: block;
        line-height: 45px;
        background: $c-white;
        border-color: $c-printText;
    }
}