@import '../../../sass/globals';

.profile-picture{
    border-radius: 50%;
    overflow: hidden;
    width: 200px;
    height: 200px;
    border: 5px solid $c-accentSecondary;
    display: inline-block;
    float: none;
    margin: 30px 0 0 0;

    img{
        display: block;
        width: 100%;
        height: auto;
    }

    .loading{
        width: 190px;
        height: 190px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media print{
    .profile-picture{
        float: left;
        width: 130px;
        height: 130px;
        margin: 0;
        border-color: $c-printText;
    }
}